
function getBotToken() {
    return "5422570280:AAHOfW7hLMVTYH-ZBGaQwy7r4jo-rVKhOxg";
}

function getGroup() {
    return "-710938296";
}


export function sendToTelegram() {

    let pTel= document.querySelector("#PhoneNumb").value;
    let pName= document.querySelector("#Name").value;
    
    console.log(pTel);
    if (pTel.length === 14) {
         
        var botToken = getBotToken();
        var group = getGroup();

        var messageText = "Call me back " + pTel;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", "https://api.telegram.org/bot" + botToken + "/sendMessage?chat_id=" + group + "&text=" + messageText+" "+pName); 
        xhr.send();
        xhr.onreadystatechange = function () {
            // If the request completed, close the extension popup
            if (xhr.readyState === 4)
                if (xhr.status === 200) window.close();
        };

        alert("Message is send, we will call back");
        document.querySelector("#PhoneNumb").value ='';
        document.querySelector("#Name").value ='';

    }
}

// export function btnSendPhoneListener() {
//     var someBtn = document.querySelector('#btnSendPhone');
//     console.log(someBtn);
//     someBtn.addEventListener('click', this, sendToTelegram);

// }

export function PhoneNumbListener() {

    document.querySelector('#PhoneNumb').addEventListener('input', function (e) {
        var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      });

    // document.querySelector('#PhoneNumb').addEventListener('input', 
    // function(e){
    // //   this.value = this.value.replace(/[^\d.]/g, '');

    // }
// )

}