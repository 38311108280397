import React, { Component } from 'react'
import { Card, Container } from 'react-bootstrap'
import ContactsUsTelegram from '../Components/ContuctUsTelegram';
import Cards from '../Components/Cards';
import Prices from '../Components/Prices';
import imgStartPage from '../assets/imgStartPage.jpg';

export default class Home extends Component {
    render() {
        return (
            <>
                <div className='bg-light bg-gradient'>

                <div className='bg-image' id='home'>
                    <Container className='justify-content-center'>
                        <Card className='mb-5 shadow-sm p-3'>
                        </Card>
                        <Card className='mb-5 shadow-sm p-3'>
                            <img src={imgStartPage} class="card-img-top rounded" alt="..." />
                        </Card>
                    </Container>
                    <Prices />
                    <Cards />
                    {/*<Container className='mt-5 align-center'>
                    <Row className='justify-content-center'>
                        <Col sm={8}>
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <img
                                        alt='img'
                                        width={150}
                                        height={150}
                                        src="https://media.istockphoto.com/photos/cleaning-carpet-in-living-room-picture-id516622260?k=20&m=516622260&s=612x612&w=0&h=jIshruebneHSPU8rQrQ66OHk-xYd5ML0NQb_ynf_tys="
                                    />
                                </div>
                                <div className=" ms-3 mt-5 mb-5">
                                    <h5>OUR PROVEN PROCESS</h5>
                                    <p>Clean and Sanitize -In addition to our standard cleaning practices of dusting, vacuuming and scouring, our team will also sanitize light switches, doorknobs, telephones,bathroom surfaces, and other commonly touched areas.
                                        Professional Staff 
                                    </p>
                                     <p>-Our residential housekeepers are extensively trained to clean every room in your home based on your specific needs, delivering the professional clean you expect.
                                        24-Hour Warranty </p>
                                        <p>-If you re ever dissatisfied with any aspect of our recent services, just give us a call within 24 hours and we’ll work with you to resolve the problem.
                                        No Contracts </p>
                                        <p>-When you choose "Cleaning TI", you can appreciate the fact that contracts are never an option. Our professional housecleaners are only as good as our last cleaning service.
                                            
                                        </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container> */}


                    <ContactsUsTelegram />
                    {/* <CarouselBox/> */}
                </div>
                </div>
            </>
        )
    }
}