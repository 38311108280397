import React, { Component } from 'react'
import { Container, Card } from 'react-bootstrap'
// import setbackground from './script.js';

export default class Prices extends Component {
    render() {
        return (

            //  <Container style={{ width: '400px' }} >
            <>
            <Container className='justify-content-center mb-3' style={{ width: '400px' }} id='prices' >
                <Card className='shadow-lg p-3' >
                    <Card.Text>
                        <h5 class="card-title text-center " >Our Prices</h5>
                        <p class="text-center ">
                            1 bed 1 bath - $70
                            <br />1 bed 1.5 bath - $82
                            <br />2 bed 1 bath   - $87
                            <br />2 bed 1.5 bath - $110
                            <br />2 bed 2 bath   - $115
                            <br />2 bed 2.5 bath - $120
                            <br />3 bed 1 bath   - $110
                            <br />3 bed 1.5 bath - $115
                            <br />3 bed 2 bath   - $125
                            <br />3 bed 2.5 bath - $135
                            <br />3 bed 3.5 bath - $145                     
                            <br />4 bed 1 bath   - $135
                            <br />4 bed 1.5 bath - $140
                            <br />4 bed 2 bath   - $150
                            <br />4 bed 2.5 bath - $165
                            <br />4 bed 3 bath   - $180
                            <br />4 bed 3.5 bath - $185
                            <br />4 bed 4 bath   - $205
                        </p>
                        
                        <p class="card-text"><small class="text-muted">
                        Basement for 2-3 bedrooms 25$, 4 and up bedrooms 32$.
                        <br/>The standart price includes cleanning of the living room, dining room, kitchen, bedrooms and bathrooms.
                        <br/>For each additional room, price plus 9$.
                        <br/>*Additional rooms: office, familu room, playroom, sport room, including those in the basenent.
                        <br/>The price is valid for regular cleaning every two weeks.
                        <br/>Monthly cleaning - the price plus 10%.
                        <br/>Weekly cleaning - the price minus 10%.
                        </small></p>
                    </Card.Text>
                </Card>
            </Container>
            </>
        )
    }
}
