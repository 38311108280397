import React, { Component } from 'react'
import { Card, Container, Col, Row } from 'react-bootstrap';
import LivingRoom from '../assets/livingroom.jpg';
import Kitchen from '../assets/kitchen.jpg';
import Bathroom from '../assets/bathroom.jpg';
import Bedroom from '../assets/bedroom.jpg';
import fridge from '../assets/fridge.jpg';
import oven from '../assets/oven.jpeg';
import basement from '../assets/basement.jpg';
import bed from '../assets/bed.jpg';

// import Prices from '../Components/Prices';

export default class Cards extends Component {
  render() {
    return (<>
      <Container className='justify-content-center'>
        <Card className='mb-3 shadow-lg p-3'>
          <Card.Text>
            <h5 class="card-title text-center text-muted">Our company works only with certified products, that are on the market. </h5>
            <p class="card-title text-center text-muted">All cleanings supplies included (upon request, we may use your items to make sure we clean your home to your satisfaction)</p>
          </Card.Text>
        </Card>
        <Card className='mb-3 shadow-sm p-3'>
          <img src={LivingRoom} class="card-img-top rounded" alt="LivingRoom" />
          <Card.Text>
            <h5 class="card-title text-center mt-3">Living room</h5>
            <p class="card-text text-muted">Check list:
              <br />-Dust and wet wipe furniture sufraces, tabels, lamps, etc.
              <br />-Dust fans.
              <br />-Dust baseboards.
              <br />-Empty trash cans.
              <br />-Clean mirrors and glass surfaces.
              <br />-Vacuum and mop, floors.
              <br />-Vacuum carpets.
            </p>
          </Card.Text>
        </Card>
        <Card className='mb-3 shadow-sm p-3'>
          <img src={Bedroom} class="card-img rounded" alt="Bedroom" />
          <Card.Text>
            <h5 class="card-title text-center mt-3">Bedroom</h5>
            <p class="card-text text-muted">Check list:
              <br />-Tiny up and organize.
              <br />-Swiffer dust furniture and decorations.
              <br />-Dust cleanin fans.
              <br />-Clean mirrors and glass surfaces.
              <br />-Dust baseboards.
              <br />-Wet wipe wood surfaces.
              <br />-Dust and wet wipe windows ledges.
              <br />-Vacuum carpets and small rugs.
              <br />-Vacuum and mop bedroom floors.
            </p>
            {/* <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> */}
          </Card.Text>
        </Card>
        <Card className='mb-3 shadow-sm p-3'>
          <Card.Text>
            <h5 class="card-title text-center mt-3">Bathroom</h5>
            <p class="card-text text-muted">Check list:
              <br />-Disinfect and scrub bathtubs(showers), toilets, sinks.
              <br />-Wipe shower door.
              <br />-Wipe domn conntetops.
              <br />-Clean mirrors.
              <br />-Wipe down the outside of bathroom cabinets.
              <br />-Empty trash cans.
              <br />-Dust baseboards.
              <br />-Vacuum bathroom rugs.
              <br />-Vacuum bathroom floors and mop.
            </p>
            {/* <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> */}
          </Card.Text>
          <img src={Bathroom} class="card-img rounded " alt="Bathroom" max-height='500px' />
        </Card>
        <Card className='mb-3 shadow-sm p-3'>
          <Card.Text>
            <h5 class="card-title text-center mt-3">Kitchen</h5>
            <p class="card-text text-muted">Check list:
              <br />-Clean stovetop.
              <br />-Wipe down cabinet and drawerdoors.
              <br />-Wipe down outside of appliances.
              <br />-Wipe down countertops.
              <br />-Clean stovetop.
              <br />-Scrub and clean sink.
              <br />-Clean inside microwave.
              <br />-Wipe down Exterier with stainless steel polish: dishwasher, fridge and oven.
              <br />-Empty recycling bin.
              <br />-Vacuum and mop kitchen floors.
            </p>
            {/* <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> */}
          </Card.Text>
          <img src={Kitchen} class="card-img rounded" alt="Kitchen" />
        </Card>
        <Card className='mb-3 shadow-sm p-3'>
          <Card.Text>
            <h5 class="card-title text-center">Extra</h5>
            <Row>
              <Col md="9">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <img
                      width={150}
                      height={150}
                      className="m-3"
                      src={fridge}
                    />
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <p class="card-text">We will remove the food from the fridge, clean the shaves, walls and the interior of your appliance inside refrigerator 30$</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="9">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <img
                      width={150}
                      height={150}
                      className="m-3"
                      src={oven}
                    />
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <p class="card-text">Clean inside the oven 25$
                      <br />We use the best products on the market to clean your oven.</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="9">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <img
                      width={150}
                      height={150}
                      className="m-3"
                      src={basement}
                    />
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <p class="card-text">Basement 25$-32$
                      <br />Basement for 2-3 bed homes 25$, 4 and up bedrooms 32$</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="9">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <img
                      width={150}
                      height={150}
                      className="m-3"
                      src={bed}
                    />
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <p class="card-text">Bed sheets 4$ per bed.
                      <br />We freshen up your beds by changing the sheets and pillow cases for each one of your beds.
                      <br /><small class="text-muted">*This price is per one bed</small></p>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Text>
        </Card>
        {/* <ContactsUsTelegram /> */}
        <Card className='mb-3 shadow-sm p-3 text-center sm-3' id='contacts'>
          <Card.Text>
            <h5 class="card-title text-center">Our contacts</h5>
            <p>Location where we serve
              <br />Chicago, IL
              <br />Park Ridge / Edison Park / Norwood Park / Morton Grove / Niles / Norridge / Rosemont</p>
            <a href="tel:18478733338" variant="danger" className="text-center">tel:+1(847)-873-3338 Tanya</a> 
          </Card.Text>
        </Card>
      </Container>
    </>)
  }

}