import React, { Component } from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import { sendToTelegram, PhoneNumbListener} from "./Telegram.js";

export default class ContactsUsTelegram extends Component {
    render() {
        return (<>
            <Container style={{ width: '400px', higth: '599px' }} className='border p-3 rounded mb-3 bg-light' >
                <h4 className='text-center' id='callback'>Call me back</h4>
                <Form >
                    <Form.Group>
                        <Form.Control className='form-control mt-1' id='Name' type="text" placeholder='Enter your name' maxlength='30' minLength='2' required />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control className='form-control' id='PhoneNumb' type="tel" placeholder="(XXX) XXX-XXXX" minLength='14' required onInput={() => { PhoneNumbListener()}}/>
                        <small>Format: (123) 456-7890</small>
                        <Form.Text>
                            <br/>*We'll never share your phone with anyone else
                        </Form.Text>
                    </Form.Group>
                    {/* <Button className='btn-outline-danger btn-sm' variant="light" type='submit' id="btnSendPhone" onClick={function(){sendToTelegram()}}>Submit</Button> */}
                    <Button className='btn-outline-primary btn-sm' variant="light" type='submit' id="btnSendPhone" onClick={() => { sendToTelegram() }}>Submit</Button>
                </Form>
            </Container>

        </>
        )
    }
}
