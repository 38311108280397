//import logo from './logo.svg';
import './App.css';
import { } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from './Components/Header';
import MyFooter from './Components/MyFooter';
const TITLE = 'My Page Title'

function App() {
  return (
    <>
      <title>{TITLE}</title>
      <div>
        <Header></Header>
        <MyFooter />
      </div>
    </>
  );
}

export default App;
