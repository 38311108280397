import React, { Component } from 'react'
import { Container } from 'react-bootstrap'

export default class MyFooter extends Component {
  render() {
    return (<>
      <Container className='justify-content-center'>
        <footer className='justify-content-center'>
          <div></div>
        </footer>
      </Container>

    </>)
  }

}