import React, { Component } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import logo from './Cleaning TI-1-2.png';
import Home from '../Pages/Home';



export default class Header extends Component {
    render() {
        return (
            <>
                {/* <Navbar  collapseOnSelect expand="sm fixed-top" bg="light" variant="light" >
                <Container>
                    <Navbar.Brand href="/">
                    <img src={logo}
                        height="30"
                        width=""
                        className="d-inline-block align-top"
                        alt="Logo">

                    </img>
                    </Navbar.Brand>
                    
                    <Nav className="mr-auto "  variant="light">
                        
                        <Nav.Link  href="#contacts">Contacts</Nav.Link>
                   </Nav>

                </Container>
            </Navbar>
                <Router>
                    <Routes>
                        <Route path='/' element={<Home />} />
                    </Routes>
                </Router> */}

                <Navbar collapseOnSelect expand="md fixed-top mb-4" bg="light" variant="light">
                    <Container>
                        <Navbar.Brand className="d-inline-block align-top justufy-content-center" href="/">
                        </Navbar.Brand>
                        <a href='#home'>
                            <img src={logo}
                                height="30"
                                width=""
                                className="d-inline-block align-top justufy-content-center"
                                alt="Logo">

                            </img>
                        </a>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="mr-auto">
                                <Nav.Link href="#home">Home</Nav.Link>
                                <Nav.Link href="#prices">Prices</Nav.Link>
                                <Nav.Link href="#contacts">Contacts</Nav.Link>
                                <Nav.Link href="#callback">Call back</Nav.Link>
                            </Nav>
                        </Navbar.Collapse >
                    </Container>
                </Navbar>
                <Router>
                    <Routes>
                        <Route path='/' element={<Home />} />
                    </Routes>
                </Router>
            </>
        )
    }

}

